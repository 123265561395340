import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';
import type { Ref } from 'vue';
import { inject } from 'vue';

export default function useBaseGridControl(): Ref<BaseGridControl> {
    const gridControl = inject(injectionKey, null);

    return gridControl;
}

export const injectionKey = Symbol('BaseGridControl');